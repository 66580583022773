<template>
    <main class="timing-hall">
        <router-view class="timing-hall__data" />
    </main>
</template>

<script>
    export default {
        name: "TimingHall",
    }
</script>
